import * as React from "react";
import styled from "styled-components";

export function PricingCard() {
    const [timeLeft, setTimeLeft] = React.useState(calculateTimeLeft());
    const handleButtonClick = () => {
        window.location.href = "https://milatimaa.net/cart/44934376652977:1"; // Replace with your actual link
    };
    React.useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer); // Очистка таймера при размонтировании
    }, []);

    function calculateTimeLeft() {
        const targetDate = new Date("2024-12-06T23:59:00+01:00"); // Время Осло (CET)
        const now = new Date();
        const difference = targetDate - now;

        if (difference <= 0) {
            return { days: 0, hours: 0, minutes: 0, seconds: 0 };
        }

        return {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60),
        };
    }

    return (
        <CardWrapper>
            <TimerSection>
                <TimerLabel>Tilbudet går ut om</TimerLabel>
                <TimerValue>
                    {timeLeft.days}d {timeLeft.hours}h {timeLeft.minutes}m {timeLeft.seconds}s
                </TimerValue>
            </TimerSection>
            <PriceSection>
                <PriceLabel>Maraton prise</PriceLabel>
                <PriceWrapper>
                    <CurrentPrice>kr 2,540</CurrentPrice>
                    <OriginalPrice>kr 2,990</OriginalPrice>
                </PriceWrapper>
            </PriceSection>
            <ActionButton onClick={() => handleButtonClick()}>Betal kr 490 for å sikre tilbudet</ActionButton>
        </CardWrapper>
    );
}

const CardWrapper = styled.div`
    border-radius: 30px;
    background: #fbdcfb;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    //width: 100%;
    height: 225px;
    //margin: 20px auto;
    //box-sizing: border-box;
    padding: 60px 20px;
    
`;

const TimerSection = styled.div`
    width: 100%;
    margin-bottom: 16px;
`;

const TimerLabel = styled.div`
    color: #000;
    font-family: "Libre Baskerville";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.8px;
    margin-bottom: 8px;
`;

const TimerValue = styled.div`
    color: #000;
    font-family: "Hind Vadodara";
    font-size: 50px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: -2.5px;
`;

const PriceSection = styled.div`
    width: 100%;
    margin-top: 16px;
`;

const PriceLabel = styled.div`
    color: #000;
    font-family: "Libre Baskerville";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.8px;
`;

const PriceWrapper = styled.div`
    display: flex;
    align-items: baseline;
    gap: 10px;
`;

const CurrentPrice = styled.div`
    color: #000;
    font-family: "Hind Vadodara";
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: -1.5px;
`;

const OriginalPrice = styled.div`
    color: rgba(0, 0, 0, 0.7);
    font-family: "Hind Vadodara";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 50px;
    text-decoration: line-through;
`;

const ActionButton = styled.button`
    margin-top: 24px;
    padding: 20px 24px;
    background-color: #000;
   
    border: none;
    border-radius: 30px;
    cursor: pointer;
    width: 100%;
    text-align: center;
    

    color: #FFF;
    leading-trim: both;
    text-edge: cap;
    font-family: "Hind Vadodara";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 100% */
    letter-spacing: -1px;
    &:hover {
        background-color: #333;
    }

    &:focus {
        outline: none;
    }
`;
