import * as React from "react";
import styled from "styled-components";

export function DiscountBanner() {
    return (
        <BannerWrapper>
            <DiscountWrapper>
                <DiscountIcon
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/c75161a941c510c795e057decb59288f25c6b569050d9c65f8428944fe1d0112?placeholderIfAbsent=true&apiKey=96c116beaede45c8ac398ab70c55eebe"
                    alt="Discount icon"
                />
                <DiscountText>15%</DiscountText>
            </DiscountWrapper>
            <BannerContent>
                <SaveText>
                    Spar kr 450 hvis du <br />
                   melder deg på nå

                </SaveText>
                <DiscountDescription>
                    En eksklusiv 15% rabatt og
                    <br />
                    sikre deg plass i januar Maraton

                </DiscountDescription>
            </BannerContent>
        </BannerWrapper>
    );
}

const BannerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 30px;
    overflow: hidden; /* Ensures child elements are clipped to the border radius */
    position: relative;
    aspect-ratio: 0.894;
    margin-top: 20px;
    width: 100%;
    color: #fff;
    padding: 40px 0 60px 0;
    background-color: #000; /* Fallback for image loading */
    background-image: url("https://cdn.builder.io/api/v1/image/assets/TEMP/546422d480f89175f16136fe7d1ad4404c61d32227341d3accbc4f3edb2049ad?placeholderIfAbsent=true&apiKey=96c116beaede45c8ac398ab70c55eebe");
    background-size: cover;
    height: 245px;
    background-position: center;
`;

const DiscountWrapper = styled.div`
    position: relative;
    align-self: flex-end;
    display: flex;
    margin-right: 24px;
    gap: 5px;
    white-space: nowrap;
    letter-spacing: -3.5px;
    font: 700 70px/1 Libre Baskerville, sans-serif;
`;

const DiscountIcon = styled.img`
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 59px;
`;

const DiscountText = styled.div`
    flex-basis: auto;
`;

const BannerContent = styled.div`
    position: relative;
    display: flex;
    //margin-top: 80px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 0 0 24px;
`;

const SaveText = styled.h2`
    letter-spacing: -1.5px;
    font: 600 30px/40px Hind Vadodara, sans-serif;
    margin-bottom: 8px;
`;

const DiscountDescription = styled.p`
    letter-spacing: -0.8px;
    margin-top: 0;
    font: 400 16px Libre Baskerville, sans-serif;
`;
