import * as React from "react";
import styled from "styled-components";

export function ReserveSpot() {
    return (
        <ReserveWrapper>
            <BackgroundSVG />
            <ReserveTitle>
                Reserver plassen din nå betal senere.
            </ReserveTitle>
            <ReserveDescription>
                Betal resten av maratonet senere  <br />
                (kr 2.050) innen 03.01.25 til
            </ReserveDescription>
        </ReserveWrapper>
    );
}

const ReserveWrapper = styled.div`
    position: relative; /* Ensures the SVG is positioned relative to this container */
    border-radius: 20px;
    background-color: #f8f9fa;
    display: flex;
    margin-bottom: 20px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 60px 20px;
    height: 225px;
    
    margin-top: 20px;
    color: #333;
    overflow: hidden; /* Clips the background if it overflows */
`;

const BackgroundSVG = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
    width: 243px;
    height: 243px;
    z-index: 0; /* Ensures it stays in the background */
    opacity: 0.1;
    background: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="243" height="243" viewBox="0 0 243 243" fill="none"><path d="M240.745 80.2196L222.82 62.2946C211.189 73.9171 192.335 73.9171 180.71 62.2946C169.078 50.6657 169.078 31.8148 180.703 20.1859L162.782 2.26087C159.828 -0.698908 155.086 -0.761682 152.202 2.12591L117.959 36.369L206.631 125.043L240.873 90.8001C243.758 87.9125 243.701 83.1731 240.745 80.2196ZM2.12929 152.205C-0.758226 155.087 -0.701731 159.826 2.25483 162.786L20.1794 180.711C31.8111 169.095 50.6584 169.101 62.2775 180.724C73.8997 192.352 73.8997 211.185 62.2963 222.826L80.2209 240.745C83.1712 243.698 87.9136 243.761 90.8011 240.873L201.402 130.272L112.737 41.598L2.12929 152.205ZM151.64 118.722C153.479 120.561 153.479 123.54 151.64 125.379L99.947 177.076C99.0274 177.996 97.8222 178.454 96.617 178.454C95.4117 178.454 94.2096 177.996 93.29 177.076C91.4508 175.237 91.4508 172.258 93.29 170.419L144.983 118.722C146.819 116.883 149.801 116.883 151.64 118.722ZM137.959 105.04C139.798 106.88 139.798 109.858 137.959 111.698L86.2627 163.395C85.3431 164.314 84.1379 164.773 82.9326 164.773C81.7274 164.773 80.5222 164.314 79.6026 163.395C77.7634 161.556 77.7634 158.577 79.6026 156.738L131.299 105.04C133.141 103.201 136.119 103.201 137.959 105.04ZM124.277 91.3557C126.117 93.1949 126.117 96.1736 124.277 98.0128L72.5847 149.716C71.6651 150.636 70.4598 151.094 69.2546 151.094C68.0494 151.094 66.8442 150.636 65.9245 149.716C64.0853 147.877 64.0853 144.899 65.9245 143.059L117.617 91.3557C119.46 89.5195 122.438 89.5195 124.277 91.3557Z" fill="black"/></svg>')
    no-repeat;
`;

const ReserveTitle = styled.h2`
    font-family: "Hind Vadodara", sans-serif;
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.2;
    color: #000;
    margin-bottom: 10px;
    z-index: 1; /* Ensures it appears above the background */
    color: #000;
    font-family: "Hind Vadodara";
    font-size: 50px;
    font-style: normal;
    font-weight: 600;
    line-height: 50px; /* 100% */
    letter-spacing: -2.5px
`;

const ReserveDescription = styled.p`
    font-family: "Libre Baskerville", sans-serif;
    font-size: 1rem;
    line-height: 1.5;
    color: #555;
    margin: 0;
    z-index: 1; /* Ensures it appears above the background */
`;
