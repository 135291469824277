import React from "react";
import styled from "styled-components";
import { DiscountBanner } from "./components/DiscountBanner";
import { ReserveSpot } from "./components/ReserveSpot";
import { PricingCard } from "./components/PricingCard";
import { FeatureList } from "./components/FeatureList";
import { createGlobalStyle } from 'styled-components';
import {SignupMarathon} from "./components/SignupMarathon";

const GlobalStyles = createGlobalStyle`
  //@import url('https://fonts.googleapis.com/css2?family=Averia+Serif+Libre:wght@400;700&display=swap');
  //@import url('https://fonts.googleapis.com/css2?family=Hind+Vadodara:wght@400;600&display=swap');

  body {
    font-family: 'Averia Serif Libre', serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #EFEFEF;
    color: #333;
  }
`;
const features = [
    { icon: "📱", text: "Myluck App" },
    { icon: "🤸", text: "Daglig vakuum og magetrening" },
    { icon: "💪", text: "Komplett treningsprogram" },
    { icon: "🥙", text: "Kostholdsplan tilpasset dine mål" },
    { icon: "🍲", text: "200+ proteinrike oppskrifter" },
    { icon: "🍫", text: "5 livestreams" },
];

export default function PricingPage() {
    return (
        <>
            <GlobalStyles />
        <PricingContainer>
            <LogoContainer>
                <LogoFirstLine>myluck</LogoFirstLine>
                <LogoSecondLine>by Mila</LogoSecondLine>
            </LogoContainer>
            {/*<HeroImage*/}
            {/*    loading="lazy"*/}
            {/*    src="https://cdn.builder.io/api/v1/image/assets/TEMP/7352d34da41cf9d9b3b31e9362f50252c657f0311d0a1b0363d2862705cbb61f?placeholderIfAbsent=true&apiKey=96c116beaede45c8ac398ab70c55eebe"*/}
            {/*    alt="Pricing hero"*/}
            {/*/>*/}
            <SignupMarathon />
            <DiscountBanner />
            <ReserveSpot />
            <PricingCard />
            <MarathonSection>
                <MarathonTitle>Om maraton</MarathonTitle>
                <MarathonImage
                    loading="lazy"
                    src="./marathon_details.png"
                    alt="Marathon details"
                />

                <MarathonImage
                    loading="lazy"
                    src="./5marathons.png"
                    alt="Marathon benefits"
                />
                <MarathonImage
                    loading="lazy"
                    src="./focus.png"
                    alt="Marathon benefits"
                />

                <MarathonImage
                    loading="lazy"
                    src="./meal_plan_benefits.png"
                    alt="meal plan benefits"
                />
                <MarathonImage
                    loading="lazy"
                    src="./meal_plan_for_you.png"
                    alt="Meal plan for you"
                />
                <MarathonImage
                    loading="lazy"
                    src="./swap_meal.png"
                    alt="Meal plan for you"
                />
                <MarathonImage
                    loading="lazy"
                    src="./training_app.png"
                    alt="Meal plan for you"
                />
                <MarathonImage
                    loading="lazy"
                    src="./workout_plan.png"
                    alt="Meal plan for you"
                />
                <MarathonImage
                    loading="lazy"
                    src="./challenges.png"
                    alt="Challanges"
                />

            </MarathonSection>
            <PricingCard />

            {/*<FeatureList features={features} />*/}
        </PricingContainer>
        </>

    );
}

const PricingContainer = styled.div`
    background: #EFEFEF;
    display: flex;
    flex-direction: column;
    max-width: 520px;
    //width: 100%;
    margin: 0 auto;
    padding: 30px 15px;
    //border-radius: 10px;
    //box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

const HeroImage = styled.img`
    //aspect-ratio: 16 / 9;
    //object-fit: cover;
    //width: 100%;
    //border-radius: 30px;
    //margin-bottom: 20px;
`;

const MarathonSection = styled.section`
    margin-top: 40px;
    margin-bottom: 20px;
    text-align: center;
`;

const MarathonTitle = styled.h2`
    color: #000;
    text-align: center;
    font-family: "Hind Vadodara";
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px; /* 133.333% */
    letter-spacing: -1.5px;
`;

const MarathonImage = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  width: 100%;
  border-radius: 10px;
  margin-top: 20px;
`;

const LogoFirstLine = styled.text`
    color: #000;
    text-align: center;
    //font-family: "Averia Serif Libre";
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -3.4px;
`
const LogoSecondLine = styled.text`
    color: #000;
    text-align: center;
    font-family: "Averia Serif Libre";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: -1.2px;`
const LogoContainer = styled.div`
    display: flex;
    flex-direction: column;
    
    margin-bottom: 20px;
`
// Adjusted styles for better spacing, visuals, and alignment
const MarathonImageLast = styled(MarathonImage)`
margin-bottom: 20px;
`
