import * as React from "react";
import styled from "styled-components";

export function FeatureList({ features }) {
    return (
        <FeatureWrapper>
            <FeatureContainer>
                {features.map((feature, index) => (
                    <FeatureItem key={index}>
                        <FeatureIcon>{feature.icon}</FeatureIcon>
                        <FeatureText>{feature.text}</FeatureText>
                    </FeatureItem>
                ))}
            </FeatureContainer>
        </FeatureWrapper>
    );
}

const FeatureWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content: center;
    padding: 24px 20px;
    overflow: hidden;
    border-radius: 30px;
    background-image: url("https://cdn.builder.io/api/v1/image/assets/TEMP/fb680ebbc3692dc43baa6dfe27a94ba0f10365bcef01b0dc02c601f98e3cc1cf?placeholderIfAbsent=true&apiKey=96c116beaede45c8ac398ab70c55eebe");
    background-size: cover;
    background-position: center;
`;

const FeatureContainer = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
`;

const FeatureItem = styled.div`
    border-radius: 1000px;
    display: flex;
    gap: 12px;
    align-items: center;
`;

const FeatureIcon = styled.div`
    border-radius: 1000px;
    background-color: rgba(255, 255, 255, 0.1);
    color: #000;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font: 700 30px Libre Baskerville, sans-serif;
    border: 1px solid rgba(255, 255, 255, 0.3);
`;

const FeatureText = styled.div`
    color: #fff;
    font: 400 16px Hind Vadodara, sans-serif;
`;
