import * as React from "react";
import styled from "styled-components";

export function SignupMarathon() {
    const handleButtonClick = () => {
        window.location.href = "https://milatimaa.net/cart/44934376652977:1"; // Replace with your actual link
    };
    return (
        <CardWrapper>
            <Title>New Year, <br/> New Luck<br/> Marathon</Title>
            <Subtitle>
                Laget for å hjelpe deg med å oppnå varig vekttap og personlig vekst.
            </Subtitle>
            <ActionButton onClick={() => handleButtonClick()}>Sikre din plass nå</ActionButton>
        </CardWrapper>
    );
}

const CardWrapper = styled.div`
    border-radius: 30px;
    background: url(first_image.png) no-repeat center center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 255px;
    padding: 120px 20px 60px 20px;
`;

const Title = styled.div`
    color: #FFF;
    font-family: "Hind Vadodara";
    font-size: 50px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px; /* 80% */
    letter-spacing: -2.5px;
    text-align: left;
`;

const Subtitle = styled.div`
    color: #FFF;
    font-family: "Libre Baskerville";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.8px;
    text-align: left;
    margin-top: 16px;
`;

const ActionButton = styled.button`
    margin-top: 24px;
    padding: 20px 24px;
    background-color: #FFF;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    width: auto;
    text-align: center;
    color: #000;
    font-family: "Hind Vadodara";
    width: 100%;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -1px;

    &:hover {
        background-color: #f0f0f0;
    }

    &:focus {
        outline: none;
    }
`;
